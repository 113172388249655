import Image from "next/image";
import {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import useEmblaCarousel, { EmblaCarouselType } from "embla-carousel-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  ArrowCircleRightIcon,
  Button,
  CaratLeftIcon,
  CaratRightIcon,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  DiscordIcon,
  Divider,
  MinusIcon,
  PlusIcon,
  SupportIcon,
} from "legacy-ui";
import Link from "next/link";
import { useRouter } from "next/router";
import { cn } from "utils";
import MarketingLayout from "../components/layout/marketing";

import auraHero from "../../public/images/aura-hero.jpg";
import featureAI from "../../public/images/feature-ai.jpg";
import featureHyperdrive from "../../public/images/feature-hyperdrive.jpg";
import featureMobileSupport from "../../public/images/feature-6.jpg";
import featureRedesigned from "../../public/images/feature-redesigned-2.jpg";
import featureImprovementsFixes from "../../public/images/feature-improvements-fixes.jpg";
import featureOffers from "../../public/images/feature-offers.jpg";
import { useAuth } from "../hooks/auth";

export const newFeatures = [
  {
    title: "AI – optimal strategies in real time",
    tag: "All plans",
    description: (
      <>
        <p className="my-3 first:mt-0 last:mb-0">
          Think of Aura as your Amazon repricing copilot, automatically
          determining the best strategy in real time for each of your listings.
          This advanced tool takes the hassle out of micromanaging your prices,
          allowing you to focus on broader aspects of your Amazon business. With
          automated repricing powered by AI, Aura adapts to market changes fast
          while following your minimum and maximum prices.
        </p>

        <p className="my-3 first:mt-0 last:mb-0">
          This intelligent approach safeguards your margins and keeps you
          competitive in the fast-paced Amazon world. Let Aura&apos;s AI
          capabilities elevate your business strategy, transforming how you
          approach Amazon sales.
        </p>
      </>
    ),
    image: featureAI,
    video: {
      webm: "/videos/feature-ai.webm",
      mp4: "/videos/feature-ai.mp4",
      ogv: "/videos/feature-ai.ogv",
    },
  },
  {
    title: "Hyperdrive",
    description: (
      <>
        <p className="my-3 first:mt-0 last:mb-0">
          Hyperdrive quickly updates prices for your top 50 Amazon listings in
          under 10 seconds, crucial for winning the buy box and increasing
          Amazon sales. This allows you to potentially reprice ~12x faster than
          your competition. Our repricing tools are designed for simplicity and
          effectiveness, maintaining your prices within the set minimum and
          maximum price boundaries. Additionally, it adapts to market trends,
          ensuring you&apos;re always competitive. Experience the ease of
          advanced repricing with a free trial and take the first step towards
          more efficient and profitable Amazon selling.
        </p>
      </>
    ),
    image: featureHyperdrive,
    video: {
      webm: "/videos/feature-hyperdrive.webm",
      mp4: "/videos/feature-hyperdrive.mp4",
      ogv: "/videos/feature-hyperdrive.ogv",
    },
  },
  {
    title: "Mobile support",
    description: (
      <>
        <p className="my-3 first:mt-0 last:mb-0">
          Aura&apos;s repricing software is crafted for the dynamic Amazon
          seller, offering mobile-friendly convenience that keeps you
          competitive wherever you are. With our robust repricing tools, manage
          your listings and make vital adjustments on-the-go, whether
          you&apos;re deep in Retail Arbitrage or simply stepping away from the
          desk. Stay connected and in control, ensuring you&apos;re always
          competitive with the buy box. It&apos;s the flexibility you need to
          thrive in the fast-paced world of selling on Amazon.
        </p>
      </>
    ),
    image: featureMobileSupport,
    video: {
      webm: "/videos/aura-mobile.webm",
      mp4: "/videos/aura-mobile.mp4",
      ogv: "/videos/aura-mobile.ogv",
    },
  },
  // {
  //   title: "All 20 marketplaces",
  //   description:
  //     "Now you can scale to all 20 marketplaces with ease, including currency conversion on sales.",
  //   image: featureMarketplaces,
  // },
  {
    title: "Real-time competitive insights",
    description: (
      <>
        <p className="my-3 first:mt-0 last:mb-0">
          Aura equips you with real-time insights into competitor offers across
          all your listings, a crucial tool for refining your repricing
          strategy. With up-to-the-minute data at your fingertips, you can
          adjust your approach to secure the buy box more consistently.
          Understanding the competitive landscape allows you to set the optimal
          price, enhancing your strategy and bolstering your chances of owning
          the buy box. Stay ahead of the game and make informed decisions that
          drive your Amazon business forward with Aura.
        </p>
      </>
    ),
    image: featureOffers,
  },
  {
    title: "Redesigned and streamlined",
    description: (
      <>
        <p className="my-3 first:mt-0 last:mb-0">
          We&apos;ve revamped Aura from the ground up, focusing on faster
          repricing and smoother integration of new features. Our redesigned
          repricing software now ensures a more efficient and user-friendly
          experience. This overhaul is all about making your Amazon selling
          experience as productive and profitable as possible. Get started with
          a free trial and see how Aura can streamline your operations, freeing
          up more time for you to drive more sales.
        </p>
      </>
    ),
    image: featureRedesigned,
  },
  {
    title: "Continuous enhancements",
    description: (
      <>
        <p className="my-3 first:mt-0 last:mb-0">
          Our commitment to building an amazing product means continuously
          updating Aura with features like support for Amazon&apos;s Low-Price
          FBA Fees, enhanced buy box tracking, bug fixes, and more accurate
          profit calculations. These improvements give you better control over
          your minimum and maximum prices, enhancing your pricing strategy and
          efficiency.
        </p>

        <p className="my-3 first:mt-0 last:mb-0">
          Discover the benefits of Aura&apos;s real-time strategy optimization
          with a free trial. It&apos;s designed to streamline your workflow,
          helping you focus more on driving more sales and less on managing the
          intricacies of selling online.
        </p>
      </>
    ),
    image: featureImprovementsFixes,
    video: {
      webm: "/videos/feature-improvements-fixes.webm",
      mp4: "/videos/feature-improvements-fixes.mp4",
      ogv: "/videos/feature-improvements-fixes.ogv",
    },
  },
];

export const allFeatures = [
  {
    title: "Workflows",
    description: "Create automated rules to manage your listings.",
  },
  {
    title: "Pricing activity",
    description: "See a log of every price update, what happened, and why.",
  },
  {
    title: "Uploads",
    description: "Update your listings in bulk using Excel or Sheets.",
  },
  {
    title: "Auto min/max",
    description:
      "Calculate values using ROI, Profit Margin, Fixed Profit, and Profit Floor.",
  },
  {
    title: "Integrations",
    description:
      "Automatically import data like costs from other tools like InventoryLab.",
  },
  {
    title: "Reports",
    description: "Export critical data to make better decisions.",
  },
  {
    title: "Analytics",
    description:
      "See key metrics and data in real-time to always know what's happening.",
  },
];

export const customerQuotes = [
  {
    name: "Patricia",
    quote:
      "... serious Apple vibes, and I love the simplicity of the AI/Maven strategy ... the AI strategy is so easy and smart and I saw a difference right away as soon as I converted all of my listings to it.",
  },
  {
    name: "Nesha Howell",
    quote:
      "Absolutely love Aura! Literally a game changer for all sellers at any level. They have best customer service EVER... The software is easy to navigate and it keeps getting better…so many reasons to choose Aura!!",
  },
  {
    name: "Ashley Carber",
    quote:
      "Aura has allowed me to spend time with my children and grow my business from 5k last year to currently 157k just this year... Since having Aura I have been able to expand my catalog and actually increase my roi from 20% to 40%+.",
  },
  {
    name: "John Carter",
    quote:
      "Prior to starting my free trial my sales were abysmal ... the software raised many of my prices without relinquishing the buy box. Within three days running Aura had increased my sales by 200% over the previous 7 days.",
  },
  {
    name: "Roschaad Milner",
    quote:
      "Very clean interface, easy to use, love the Al repricing idea, and we definitely see an increase in sales AND margin, which is the main job of a repricer and you guys seem to be crushing it on the functionality front from the data that we have so far.",
  },
];

export function CustomerQuotesCarousel({
  quotes,
  className = "",
}: {
  quotes: { name: string; handle?: string; quote: string }[];
  className?: string;
}) {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnEnabled(emblaApi.canScrollPrev());
    setNextBtnEnabled(emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className={className}>
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex gap-6 tablet:gap-10">
          {quotes.map((quote, i) => (
            <div
              key={i}
              className="flex h-80 min-w-0 max-w-[247px] flex-[0_0_100%] flex-col justify-between rounded-[28px] bg-legacy-neutral-200 px-6 py-10 tablet:max-w-[733px] tablet:px-10"
            >
              <p className="line-clamp-6 text-xl tablet:line-clamp-5 tablet:text-2xl tablet:leading-10">
                &ldquo;{quote.quote}&rdquo;
              </p>

              <p className="mt-3.5 leading-[26px]">{quote.name}</p>
              {quote.handle ? (
                <p className="leading-[26px]">{quote.handle}</p>
              ) : null}
            </div>
          ))}
        </div>
      </div>

      <div className="mt-6 flex justify-end gap-3 tablet:mt-10">
        <Button
          variant="marketing_black"
          className="h-11"
          disabled={!prevBtnEnabled}
          onClick={scrollPrev}
          icon={<CaratLeftIcon className="size-4 flex-none" />}
        />
        <Button
          variant="marketing_black"
          className="h-11"
          disabled={!nextBtnEnabled}
          onClick={scrollNext}
          icon={<CaratRightIcon className="size-4 flex-none" />}
        />
      </div>
    </div>
  );
}

export function FeatureCard({
  title,
  description,
  href,
}: {
  title: string;
  description: string;
  href?: string;
}) {
  return (
    <div className="rounded-[28px] p-10 inner-border inner-border-legacy-neutral-200">
      <h2 className="text-xl font-bold">{title}</h2>
      <p className="mt-3">{description}</p>
      {href ? (
        <Button
          href={href}
          newTab
          variant="marketing_text_secondary"
          className="mt-12"
          icon={<ArrowCircleRightIcon className="size-4 flex-none" />}
        >{`More on ${title.toLowerCase()}`}</Button>
      ) : null}
    </div>
  );
}

export function AccordionButton({ className = "" }: { className?: string }) {
  return (
    <div
      className={cn(
        "flex size-11 items-center justify-center rounded-md bg-legacy-neutral-200 text-legacy-neutral-900 transition duration-100 hover:bg-legacy-neutral-300 group-hover:bg-legacy-neutral-300",
        className
      )}
    >
      <PlusIcon className="size-4 flex-none group-data-[state=open]:hidden" />
      <MinusIcon className="size-4 flex-none group-data-[state=closed]:hidden" />
    </div>
  );
}

export default function Home({}: {}) {
  const router = useRouter();
  const { authUser } = useAuth();
  const [allFeaturesOpen, setAllFeaturesOpen] = useState(false);

  useEffect(() => {
    if (!router.isReady) return;
    const { r } = router.query;
    if (r !== "0" && authUser != null) {
      // Redirect if authenticated unless explicitly directed not to with `r` query param set to 0 (/?r=0)
      // Example of this usage is on auth pages (view auth.js layout, logo link)
      router.push("/dashboard");
    }
  }, [router, authUser]);

  return (
    <>
      <div className="mx-auto max-w-screen-laptop px-4 pb-10 pt-28 tablet:px-20 tablet:pb-16">
        <h1 className="text-4xl font-bold tablet:text-6xl">
          The modern Amazon repricer, powered by AI.
        </h1>

        <div className="grid grid-cols-1 gap-10 tablet:grid-cols-2">
          <div className="mt-3">
            <p className="text-base font-normal">
              Start growing your sales profitably by automating your pricing—in
              under 10 minutes. Free 14-day trial, no credit card required.
            </p>

            <div className="mt-6 flex items-center gap-6">
              <Button variant="marketing_black" href="/signup">
                Begin trial
              </Button>
              <Button
                variant="marketing_text_secondary"
                icon={<SupportIcon className="size-4 flex-none" />}
                onClick={() => {
                  if ((window as any).Intercom) {
                    (window as any).Intercom("showNewMessage");
                  }
                }}
              >
                Chat with us
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-screen-desktop px-4 pb-14 tablet:px-20 tablet:pb-28">
        <div className="relative aspect-[1440/652] overflow-hidden rounded-[28px] bg-black">
          <Image
            alt="Aura 1.0 Walkthrough"
            src={auraHero}
            className="rounded-[28px]"
          />
          <video
            autoPlay
            muted
            loop
            playsInline
            className="absolute left-0 top-0 size-full rounded-[28px] object-cover object-top"
            poster={auraHero.src}
          >
            <source src="/videos/aura-hero.webm" type="video/webm" />
            <source src="/videos/aura-hero.mp4" type="video/mp4" />
            <source src="/videos/aura-hero.ogv" type="video/ogg" />
          </video>
        </div>
      </div>

      <Divider color="border-legacy-neutral-200" />

      <div className="mx-auto max-w-screen-laptop px-4 py-14 tablet:px-20 tablet:py-28">
        <h2 className="text-xl">What&apos;s new in Aura 1.0</h2>

        <Accordion
          type="single"
          defaultValue={newFeatures[0].title}
          collapsible
          className="mt-10 w-full divide-y divide-legacy-neutral-200 tablet:mt-16"
        >
          {newFeatures.map((feature, i) => (
            <AccordionItem
              key={i}
              value={feature.title}
              className="py-4 last:!pb-0 data-[state=open]:py-14 first:data-[state=closed]:border-t first:data-[state=closed]:border-t-legacy-neutral-200 first:data-[state=open]:pt-0 tablet:data-[state=open]:py-28"
            >
              <AccordionTrigger className="group data-[state=open]:hidden">
                <h3 className="text-left text-xl font-bold">{feature.title}</h3>
                <AccordionButton />
              </AccordionTrigger>
              <AccordionContent>
                <div className="grid grid-cols-1 items-center gap-10 tablet:grid-cols-2">
                  <div>
                    {feature.tag ? (
                      <div className="mb-3 inline-block rounded-md bg-[#E09550] px-1 text-sm text-white">
                        {feature.tag}
                      </div>
                    ) : null}
                    <h3 className="text-xl font-bold tablet:text-5xl">
                      {feature.title}
                    </h3>
                    <div className="mt-3">{feature.description}</div>
                  </div>

                  {feature.video != null ? (
                    <div className="relative aspect-square overflow-hidden rounded-[28px]">
                      <Image
                        alt={feature.title}
                        src={feature.image}
                        className="aspect-square rounded-[28px] object-cover"
                      />
                      <video
                        autoPlay
                        muted
                        loop
                        playsInline
                        className="absolute left-0 top-0 size-full rounded-[28px] object-cover"
                        poster={feature.image.src}
                      >
                        <source src={feature.video.webm} type="video/webm" />
                        <source src={feature.video.mp4} type="video/mp4" />
                        <source src={feature.video.ogv} type="video/ogg" />
                      </video>
                    </div>
                  ) : (
                    <Image
                      alt={feature.title}
                      src={feature.image}
                      className="aspect-square rounded-[28px] object-cover"
                    />
                  )}
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>

      <Divider color="border-legacy-neutral-200" />

      <div className="mx-auto max-w-screen-laptop px-4 py-14 tablet:px-20 tablet:py-28">
        <h2 className="text-xl">All Aura features</h2>

        <Collapsible open={allFeaturesOpen} onOpenChange={setAllFeaturesOpen}>
          <div className="gap mt-16 grid auto-rows-fr grid-cols-1 gap-6 tablet:grid-cols-3 tablet:gap-10">
            {allFeatures.slice(0, 6).map((feature) => (
              <Fragment key={feature.title}>
                <FeatureCard {...feature} />
              </Fragment>
            ))}
            <CollapsibleContent asChild>
              <>
                {allFeatures.slice(6).map((feature) => (
                  <Fragment key={feature.title}>
                    <FeatureCard {...feature} />
                  </Fragment>
                ))}
              </>
            </CollapsibleContent>
          </div>

          <CollapsibleTrigger asChild>
            <Button
              variant="marketing_text_secondary"
              className="mx-auto mt-10 flex"
            >
              Show {allFeaturesOpen ? "less" : "more"} features
            </Button>
          </CollapsibleTrigger>
        </Collapsible>
      </div>

      <Divider color="border-legacy-neutral-200" />

      <div className="mx-auto max-w-screen-laptop px-4 py-14 tablet:px-20 tablet:py-28">
        <h2 className="text-xl">Aura support</h2>

        <div className="mt-10 grid grid-cols-1 gap-6 tablet:mt-16 tablet:grid-cols-2 tablet:gap-10">
          <h3 className="text-3xl font-bold tablet:text-5xl">
            Support that is never outsourced.
          </h3>

          <div className="grid auto-rows-fr grid-cols-1 gap-6 tablet:gap-10">
            <div className="flex h-[250px] flex-col rounded-[28px] px-6 py-10 inner-border inner-border-legacy-neutral-200 tablet:px-10">
              <h4 className="text-xl font-bold">Chat</h4>
              <p className="mt-3">
                World-class customer support based in Boston, MA. Chat with our
                support team 5 days a week from 9 to 5 EST.
              </p>
            </div>

            <div className="flex h-[250px] flex-col rounded-[28px] px-6 py-10 inner-border inner-border-legacy-neutral-200 tablet:px-10">
              <h4 className="text-xl font-bold">Community</h4>
              <p className="mt-3">
                Chat with Aura pros around the world 24 hours/7 days a week.
              </p>

              <div className="mt-auto">
                <Link
                  className="inline-block rounded-md bg-legacy-primary-400 px-4 pb-2.5 pt-2 text-base text-legacy-neutral-900 transition duration-100 hover:bg-legacy-primary-500"
                  href="https://discord.gg/amazonfba"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="flex items-center justify-center gap-2.5">
                    <DiscordIcon className="size-4 flex-none" />
                    Meet our community
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider color="border-legacy-neutral-200" />

      <div className="mx-auto max-w-screen-laptop px-4 py-14 tablet:px-20 tablet:py-28">
        <h2 className="text-xl">Our customers</h2>
        <CustomerQuotesCarousel
          className="mt-10 tablet:mt-16"
          quotes={customerQuotes}
        />
      </div>

      <Divider color="border-legacy-neutral-200" />

      <div className="mx-auto max-w-screen-laptop px-4 py-14 tablet:px-20 tablet:py-28">
        <div className="grid grid-cols-1 gap-6 tablet:grid-cols-2 tablet:gap-10">
          <div>
            <h2 className="text-3xl font-bold tablet:text-5xl">
              Try Aura free for 14 days.
            </h2>

            <div className="mt-6 flex items-center gap-6">
              <Button variant="marketing_black" href="/signup">
                Begin trial
              </Button>
              <Button
                variant="marketing_text_secondary"
                icon={<SupportIcon className="size-4 flex-none" />}
                onClick={() => {
                  if ((window as any).Intercom) {
                    (window as any).Intercom("showNewMessage");
                  }
                }}
              >
                Chat with us
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Divider color="border-legacy-neutral-200" />
    </>
  );
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <MarketingLayout>{page}</MarketingLayout>;
};
Home.theme = "light";
